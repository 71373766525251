import request from '@/utils/request'

// 查询徽章配置列表
export function listBadge(query) {
  return request({
    url: '/config/badge/list',
    method: 'get',
    params: query
  })
}

// 查询徽章配置详细
export function getBadge(id) {
  return request({
    url: '/config/badge/' + id,
    method: 'get'
  })
}

// 新增徽章配置
export function addBadge(data) {
  return request({
    url: '/config/badge',
    method: 'post',
    data: data
  })
}

// 修改徽章配置
export function updateBadge(data) {
  return request({
    url: '/config/badge',
    method: 'put',
    data: data
  })
}

// 删除徽章配置
export function delBadge(id) {
  return request({
    url: '/config/badge/' + id,
    method: 'delete'
  })
}

// 查询徽章分类下拉列表
export function loadFilterOptions(query) {
  return request({
    url: '/config/badge/type/loadFilterOptions',
    method: 'get',
    params: query
  })
}
// 查询徽章分组下拉列表
export function loadFilterOptionsGroup(query) {
  return request({
    url: '/config/badge/group/listGroup',
    method: 'get',
    params: query
  })
}
export function userBadge(params) {
  return request({
    url: '/user/user/badge/user-badge-list',
    method: 'get',
    params: params
  })
}
export function send(data) {
  return request({
    url: '/user/user/badge/user-send',
    method: 'post',
    data: data
  })
}
export function revoke(data) {
  return request({
    url: '/user/user/badge/user-revoke',
    method: 'post',
    data: data
  })
}